.button {
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;

  &.is-primary {
    color: $color-black;
  }

  &.is-outlined {
    &.is-primary {
      color: $color-black;
    }
  }

  &.has-word-wrapping {
    @include until($tablet) {
      white-space: normal;
      height: auto;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }

  &.is-black {
    color: $color-white;
    background-color: $color-black;
  }

  &.has-icon {
    padding-left: 2.5rem;

    &::before {
      position: absolute;
      content: '';
      width: 3rem;
      height: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: center center / auto 0.825rem no-repeat;
    }
  }

  &.icon-download {
    &::before {
      background-image: url('/images/icon-download.svg');

      @include until(374px) {
        background: none;
      }
    }
  }

  &.is-multiline {
    white-space: inherit;
    line-height: 1em;
  }
}
