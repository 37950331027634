.template-tagesgeld {
  .counter {
    &:last-child {
      margin-top: 1rem;
    }

    .column {
      padding-right: 2.5rem;
    }
  }
}
