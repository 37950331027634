
.columns {
  &.has-no-margin-mobile {
    margin: 0 -0.75rem;
    @include from($tablet) {
      margin-top: 1rem;
    }
  }
}

.column {
  &.is-vcentered {
    align-self: center;
  }

  &.is-vcentered-tablet {
    align-self: normal;
    @include from($tablet) {
      align-self: center;
    }
  }

  &.is-first {
    order: 0;
  }

  &.has-negative-margin-mobile {
    margin: 0 -1.5rem;
    padding-top: 2rem;
    @include from($tablet) {
      margin: 0;
    }
  }

  &.is-first-mobile {
    @include until($tablet) {
      order: -1;
    }
  }

  &.is-order-1-mobile {
    @include until($tablet) {
      order: 1;
    }
  }

  &.is-order-2-mobile {
    @include until($tablet) {
      order: 2;
    }
  }

  &.is-order-3-mobile {
    @include until($tablet) {
      order: 3;
    }
  }

  &.is-order-4-mobile {
    @include until($tablet) {
      order: 4;
    }
  }

  &.is-order-5-mobile {
    @include until($tablet) {
      order: 5;
    }
  }

  &.is-order-6-mobile {
    @include until($tablet) {
      order: 6;
    }
  }
}

.is-visible-widescreen {
  display: none;

  @include from($widescreen) {
    display: flex;
  }
}
