.modal-content {

  @include tablet-only() {
    width: 90%;
  }

  padding: 2rem 2rem 4rem;
  overflow-x: hidden;

  .is-full {
    position: relative;
    margin-bottom: -4rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -10rem;
      width: 10rem;
      height: 100%;
      background: $color-grey;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -10rem;
      width: 10rem;
      height: 100%;
      background: $color-grey;
    }
  }

  &.is-small {
    @include from($desktop) {
      width: 40%;
    }
  }
}

.modal-close {
  position: absolute;

  &::before,
  &::after {
    background-color: $color-black;
  }
}
