
body {
  font-family: 'RenaultLife', sans-serif;
}

html {
  overflow-x: hidden;
}

a {
  color: inherit;
}

p {
  font-weight: $font-weight-light;
}

video {
  max-width: 100%;
}

.color-white {
  color: $color-white;
}

.counter {
  counter-reset: counter;

  .is-counted {
    &::before {
      line-height: 1.5;
      @include from($tablet) {
        line-height: 1.39;
      }
      @include from($desktop) {
        @include fs-heading(3);
        line-height: 1.5;
      }
      @include from($widescreen) {
        line-height: 1.4;
      }
      content: counters(counter, '') '.';
      counter-increment: counter;
      display: inline-block;
      width: 44px;
      height: 44px;
      margin-right: 1rem;
      text-align: center;
      color: $color-primary;
      border-radius: 50%;
      border: 2px solid $color-primary;
    }

    &.is-animated {
      position: relative;
      display: inline-block;

      &::before,
      &::after {
        top: 0;
        left: 0;
      }

      &::after {
        transform: rotate(0);
      }

      &::before {
        border: 2px solid transparent;
      }

      .is-visible &::before {
        border-top-color: $color-primary;
        border-right-color: $color-primary;
        border-bottom-color: $color-primary;

        transition: border-top-color 0.125s linear 0.5s,
        border-right-color 0.15s linear 0.6s,
        border-bottom-color 0.15s linear 0.7s;
      }

      &::after {
        content: '';
        position: absolute;
        border-radius: 100%;
        width: 44px;
        height: 44px;
        border: 0 solid transparent;
      }

      .is-visible &::after {
        border-top: 2px solid $color-primary;
        border-left-width: 2px;
        border-right-width: 2px;
        transform: rotate(270deg);
        transition: transform 0.4s linear 0.5s,
        border-left-width 0s linear 0.85s; // Solid edge post-rotation
      }
    }
  }
}

.is-hcentered {
  display: inline-block;
  margin: 0 auto;

  &.has-text-centered {
    text-align: center;
  }

  &.has-text-left {
    text-align: left;
  }

  &.has-text-right {
    text-align: right;
  }
}

.has-no-padding {
  padding: 0 !important;
}

.has-no-padding-mobile {
  @include until($tablet) {
    padding: 0 !important;
  }
}

.has-tiny-padding {
  padding: 1rem 1.5rem;
}

.has-small-padding {
  padding: 2rem 3rem;
}

.has-medium-padding {
  padding: 2.5rem 3.5rem;
}

.has-divider-bottom {
  border-bottom: 10px solid $color-primary;
}

.has-background-grey {
  background-color: $color-grey;
}

.has-background-grey-dark {
  background-color: $color-grey-dark;
}

.has-radial-gradient {
  position: relative;

  > * {
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    background: radial-gradient(ellipse at center 60%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 128, 128, 0) 70%) center center/100% no-repeat;
    top: 0;
    left: -25%;
    width: 150%;
    height: 100%;
    z-index: 0;
  }

  &.is-bottom-half {
    &::after {
      top: 50%;
      height: 50%;
    }
  }
}
