
.hero {
  color: $color-black;

  &.has-background-grey {
    background-color: $color-grey;
  }

  &.is-top {
    position: relative;
    background: url('/images/visual-home-winter-mobile.jpg') top center / 0 no-repeat;

    @include until($desktop) {
      &::before {
        content: '';
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 6 / 10 * 100%;
        background-image: inherit;
        background-size: cover;
        background-position: center;
      }
    }


    .hero-body {
      background-color: $color-primary;
      margin-bottom: 1rem;
      padding: 1.5rem 1rem;
    }


    @include from($desktop) {
      background: url('/images/visual-home-winter.jpg') center center / cover;
      color: $color-white;

      &.is-large {
        .hero-body {
          background-color: transparent;
          margin: 0;
          padding-top: 7.5rem;
          padding-bottom: 18rem;
        }
      }
    }
  }

  &.is-grey {
    background: $color-grey;
  }

  &.is-primary {
    color: $color-black;
  }

  &.is-medium {
    .hero-body {
      padding-top: 5vw;
      padding-bottom: 5vw;
    }
  }

  &.is-slider {
    width: 100%;

    &.is-split {
      @include until($tablet) {
        &::before {
          height: 0;
          background-size: cover;
        }
      }
    }


    .slider-ready & {
      height: 100%;
    }

    .hero-body {
      align-content: center;
    }
  }

  &.is-split {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 0;

    &::before {
      background-image: inherit;
      background-size: contain;
      background-position: center;

      @include until($tablet) {
        background-size: cover;
      }
    }

    &.has-aligned-background {
      @include from($tablet) {
        &::before {
          background-position: right 0;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    @include from($tablet) {
      background-position: 48vw center;
      background-size: 0;
      padding-top: 0;
    }

    &.has-mobile-padding-10-8 {
      &::before {
        padding-top: 80%;
        @include from($tablet) {
          padding-top: 0;
        }
      }
    }

    &.has-mobile-padding-10-6 {
      &:before {
        padding-top: 60%;
        background-size: cover;
        @include from($tablet) {
          padding-top: 0;
        }
      }
    }

    &.is-reverse {
      background-position: top;
      @include from($tablet) {
        background-position: 0 center;
      }

      &.has-aligned-background {
        @include from($tablet) {
          &::before {
            background-position: left 0;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &.is-regular {
      @include from($tablet) {
        background-position: center right;
      }
    }

    &::before {
      position: relative;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include from($tablet) {
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        height: 100%;
        width: 50%;
        background-image: inherit;
        background-position: center center;
        background-size: cover;
      }
    }

    &.is-reverse {
      &::before {
        @include from($tablet) {
          left: 0;
          right: auto;
        }
      }
    }

    &.is-contained {
      &::before {
        @include from($tablet) {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
        }
      }

      &.is-reverse {
        &::before {
          @include from($tablet) {
            background-position: left;
          }
        }
      }
    }
  }

  &.is-decorated-mobile {
    @include until($tablet) {
      position: relative;

      &::after {
        content: '';
        position: absolute;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        background: url('/images/decorator-snow.png') center center / cover no-repeat;
      }
    }
  }

}


.flickity-viewport {
  margin-bottom: 1rem;
}

.flickity-prev-next-button {
  top: 45%;

  &.next {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    right: 0;
  }

  &.previous {
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    left: 0;
  }
}
