.intrinsic-box {

  &.is-1-1-mobile {
    @include until($tablet) {
      padding-top: 100%;
    }
  }

  .intrinsic-box-content {
    &.is-bottom-aligned {
      width: 100%;
    }
  }

  &.is-relative-mobile {
    @include until($tablet) {
      padding: 1rem;
      height: auto;

      .intrinsic-box-content {
        position: relative;
        top: 0;
        left: 0;
        padding: 0;
        transform: none;
      }
    }
  }

  &.is-extrinsic-touch {
    @include until($desktop) {
      padding-top: 0;
      height: auto;

      .intrinsic-box-content {
        top: auto;
        transform: translate(0, 0);
        left: auto;
        position: relative;
      }
    }
  }
}
