.store-badges {
  .store-badge {
    display: inline-block;
    height: 50px;

    img {
      height: 100%;
    }

    @include from($fullhd) {
      height: 60px;
    }

    @include from($tablet) {
      & + .store-badge {
        margin-left: 2rem;
      }
    }
  }
}
