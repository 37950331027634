// box is-centered is-yellow box-padding-medium"

.box {
  &.is-centered {
    text-align: center;
  }

  &.is-primary {
    background-color: $color-primary;
  }

  &.has-medium-padding {
    padding: 2.5rem;
  }

  &.has-small-padding {
    padding: 1.5rem;
  }
}
