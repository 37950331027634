.template-404 {
  .hero {
    &.is-contained {
      &::before {
        background-color: $color-grey-404;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
