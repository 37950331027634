@import 'node_modules/bulma/sass/utilities/functions';

// Fonts
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$nfx-typography-paragraph-size: (
  min: 16px,
  max: 17px
);

$nfx-typography-heading-sizes: (
  1: (min: 32px, max: 50px),
  2: (min: 26px, max: 42px),
  3: (min: 19px, max: 30px),
  4: (min: 19px, max: 24px),
  5: (min: 16px, max: 24px),
);

// Colors

$color-primary: #fc3;
$primary: $color-primary;

$color-black: #000;
$color-white: #fff;
$color-grey: #f4f3f0;
$grey: $color-grey;
$color-grey-dark: #333;
$color-grey-medium: #515151;
$color-grey-light: #dbdbdb;
$color-grey-lighter: #909090;
$color-grey-lightest: #f4f3f0;
$color-grey-404: #ececec;

$success: #169129;
$danger: #c22;

// sass-lint:disable function-name-format
$colors: (('white': ($color-white, $color-black), 'black': ($color-black, $color-white), 'primary': ($primary, findColorInvert($primary)), 'success': ($success, findColorInvert($success)), 'danger': ($danger, findColorInvert($danger))));
$shades: ();

$color-black-transparent: rgba($color-black, 0.15);
$color-white-transparent: rgba($color-white, 0.15);

// Sections
$section-padding: 5rem 1.5rem;
$section-padding-mobile: 1.5rem 1.5rem 2.5rem 1.5rem;

// Tabs
$tabs-link-color: $color-black;
$tabs-link-active-color: $color-black;
$tabs-border-bottom-color: $color-grey;
$tabs-border-bottom-width: 7px;
$tabs-link-active-border-bottom-color: $color-primary;

// Navbar
$navbar-dropdown-arrow: transparent;
$navbar-dropdown-background-color: $color-grey-dark;
$navbar-dropdown-item-hover-background-color: inherit;
$navbar-dropdown-item-hover-color: $color-white;
$navbar-item-hover-background-color: $color-white;
$navbar-item-hover-color: inherit;
$navbar-dropdown-border-top: 0;
$navbar-dropdown-radius: 0;
$navbar-item-img-max-height: none;

// Controls
$control-radius: 5px;
$control-height: 2.75rem;

// Modals
$modal-content-width: 60%;
$modal-content-spacing-tablet: 80px;

// Forms
$label-weight: $font-weight-normal;
