.form {
  .form-headline {
    margin-bottom: 1.25rem;
    padding-left: 0.5rem;
    font-weight: $font-weight-normal;
  }

  .form-info {
    margin-bottom: 1.25rem;
    padding-left: 0.5rem;
    color: $color-grey-lighter;
  }
}

.input {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.field {
  &.has-addons {
    .control {
      &:last-child {
        .button { // sass-lint:disable-line nesting-depth
          border-bottom-left-radius: $control-radius;
          border-top-left-radius: $control-radius;
        }
      }
    }
  }
}

.field {
  .label,
  .help {
    padding-left: 0.5rem;
  }

  + [class*='heading-'] {
    margin-top: 3rem;
  }

  .is-vcentered {
    align-self: center;
  }

  &.is-submit {
    margin-top: 2rem;
  }
}
