.table {
  background-color: $color-white;

  .table-row {
    &:last-of-type {
      .table-cell {
        border-bottom: 1px solid $color-grey;
      }
    }

    .table-cell {
      border-top: 1px solid $color-grey;
    }
  }

  .table-cell {
    &:last-of-type {
      border-left: 1px solid $color-grey;
    }
  }
}
