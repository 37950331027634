@each $level, $sizes in $nfx-typography-heading-sizes {
  .heading-#{$level} {
    font-weight: $font-weight-normal;

    p {
      font-size: inherit;
    }
  }
}

p {
  line-height: 1.6;
}

.text-flow {
  & > *:not(.no-flow) + * {
    margin-top: 1.5rem;
  }

  &.is-large > *:not(.no-flow) + * {
    margin-top: 2.25rem;
  }

  &.is-narrow > *:not(.no-flow) + * {
    margin-top: 0.75rem;
  }

  &.is-tiny > *:not(.no-flow) + * {
    margin-top: 0.375rem;
  }

  &.is-small-mobile > *:not(.no-flow) + * {
    @include until($desktop) {
      margin-top: 1rem;
    }
  }
}
