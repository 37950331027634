.badge {
  position: relative;
  width: 1rem;
  height: 1rem;
  font-size: 10px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  background-color: $color-primary;
  color: $color-black;
  top: calc((1rem - 1em)/2);
  margin-left: 0.25rem;

  &::before {
    content: attr(data-count);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
