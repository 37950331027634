html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

img {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  max-width: 100%;
}
