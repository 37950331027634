.nav {
  li {
    & + li {
      margin-top: 0.75rem;
    }
  }

  &.is-horizontal {
    li {
      display: inline-block;

      & + li {
        margin-top: 0;
        margin-left: 3rem;
      }
    }
  }

  &.is-plain {
    list-style-type: none;

    a {
      text-decoration: none;
    }
  }

  &.has-title {
    >:first-child {
      font-weight: $font-weight-normal;
    }
  }
}


.navbar {
  font-size: 17px;
  box-shadow: 0 3px 6px $color-black-transparent;
  position: relative;
  z-index: 10;

  .navbar-brand {
    padding: 0 0 0 4.5rem;
    background-color: $color-white;

    @include from($desktop) {
      padding: 1.25rem 1.25rem 1.25rem 1.875rem;
    }
  }

  .navbar-burger {
    width: 60px;
    height: 60px;
    background-color: $color-grey;
    margin-left: 0;
    @include from($desktop) {
      height: auto;
    }

    span {
      background-color: currentColor;
      left: calc(50% - 12px);
      width: 24px;
      height: 2px;

      &:nth-child(1) {
        top: calc(50% - 12px);
      }

      &:nth-child(2) {
        top: calc(50% - 5px);
      }

      &:nth-child(3) {
        top: calc(50% + 2px);
      }

      &.is-closed-text,
      &.is-open-text {
        bottom: 9px;
        left: 0;
        font-size: 10px;
        height: auto;
        width: 100%;
        text-align: center;
        background-color: transparent;
      }

      &.is-closed-text {
        display: block;
      }

      &.is-open-text {
        display: none;
      }

    }

    &.is-active {
      span {
        &:nth-child(1) {
          top: calc(50% - 9px);
        }

        &:nth-child(3) {
          top: calc(50% + 1px);
        }
      }

      .is-closed-text {
        display: none;
      }

      .is-open-text {
        display: block;
      }
    }
  }

  .navbar-menu {
    background-color: $color-white;
    @include from($widescreen) {
      padding-right: 1.875rem;
    }
  }

  .navbar-start {
    justify-content: center;
    flex-basis: 50%;

    @include from($navbar-breakpoint) {
      margin-left: auto;
    }
  }

  .navbar-link {
    text-decoration: none;
    padding: 0.5rem 0.75rem;

    @include from($widescreen) {
      padding: 0.5rem 1.25rem;
    }
  }

  .navbar-dropdown {
    font-size: 17px;
    font-weight: $font-weight-normal;
    left: -2rem;
    min-width: 150%;
    padding: 1.25rem;

    .navbar-item {
      color: $color-white;
      font-weight: $font-weight-light;
      text-decoration: none;
      padding-left: 0.75rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &.is-active {
        color: $color-white;
      }

      &:hover {
        background: $color-grey-dark;
      }

      & + .navbar-item {
        border-top: 1px solid $color-white-transparent;
      }
    }
  }

  .navbar-item {
    &.is-primary {
      position: absolute;
      width: 60px;
      height: 60px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      background-color: $color-primary;

      @include from($desktop) {
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: auto;
        height: auto;
      }

      .link {
        padding-left: 0;
        font-size: 8px;
        display: block;
        text-align: center;
        padding-top: 30px;
        @include from($desktop) {
          padding-left: 1.75rem;
          padding-top: 0;
          font-size: 14px;
        }

        &::before {
          position: absolute;
          width: 100%;
          height: 24px;
          transform: translateY(-75%);
          @include from($desktop) {
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }

    &.mobile-search {
      margin-left: auto;
      width: 60px;

      @include until(321px) {
        display: none;
      }

      &.is-grey {
        padding-left: 1.25rem;
      }

      .icon-search {
        transform: translateY(0.25rem);
        padding-top: 1.5rem;
      }

      .navbar-dropdown {
        background-color: $color-grey-dark;
        display: none;
        z-index: 1;

        form {
          width: 100%;
          @include from($tablet) {
            width: 80%;
          }
        }
      }

      &:hover {
        .navbar-dropdown {
          display: block;
        }
      }
    }

    &.is-hoverable {
      position: relative;

      &.is-active {
        background-color: $color-white;
      }

      > .is-active,
      &.is-active {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 1.25rem;
          right: 1.25rem;
          border-top: 7px solid $color-primary;
          z-index: 5;
        }
      }

      &:hover {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 1.25rem;
          right: 1.25rem;
          border-top: 7px solid $color-grey-dark;
          z-index: 5;
        }
      }
    }

    &.is-grey {
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: $color-grey;
    }

    &.is-grey-left {
      border-right: 1px solid $color-grey-light;

      &:hover {
        &::before {
          left: 1.25rem;
          right: 1rem;
          @include from($desktop) {
            left: 2rem;
          }
        }
      }

    }

    &.is-grey-right {

      &:hover {
        &::before {
          left: 1rem;
          right: 2rem;
        }
      }

    }

    &.has-logo-image {
      display: none;

      @include from($fullhd) {
        display: flex;
      }
    }

    &.has-logo {
      padding: 0;
      text-decoration: none;
      color: inherit;
      flex-grow: 1;
    }

    &.has-link {
      align-items: center;
    }

    &.has-full-dropdown {
      position: static;

      &::before {
        content: none !important;
      }

      .navbar-dropdown {
        position: absolute;
        min-width: 100%;
        max-width: 100%;
        top: 100%;
        left: 0;
      }
    }

  }

  .has-full-dropdown {
    .section {
      color: $color-white;
    }
  }

  .navbar-menu-mobile {
    display: none;
    position: relative;
    background-color: $color-white;

    &.is-active {
      display: block;
      @include from($desktop) {
        display: none;
      }
    }

    .navbar-link,
    .navbar-item {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .accordion {
      margin-top: 0;
      border-bottom: 1px solid $color-grey-light;

      .accordion-content {
        padding: 0;

        a {
          border-top: 1px solid $color-grey;
          display: block;
          padding: 0.75rem 1rem 0.5rem;
          border-bottom: 3px solid transparent;

          &:hover {
            background-color: $color-grey;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      a {
        text-decoration: none;

        &.is-decorated {

          &.is-active {

            &:after {
              border-bottom: 0;
            }
          }
        }
      }

      .accordion-title {
        background-color: $color-grey;
      }

      .is-current-page {
        border-bottom: 3px solid $color-primary;

        + .accordion-content {
          transition: max-height 0.1s ease-in-out;

          .is-active {
            padding-left: 2rem;

            &:before {
              content: '';
              width: 4px;
              height: 20px;
              display: block;
              left: 1rem;
              top: 11px;
              position: absolute;
              background-color: $color-primary;
            }
          }
        }
      }

      .page-has-children {

        .accordion-content {
          .is-active {
            padding-left: 2rem;

            &:before {
              content: '';
              width: 4px;
              height: 20px;
              display: block;
              left: 1rem;
              top: 11px;
              position: absolute;
              background-color: $color-primary;
            }
          }
        }
      }
    }

    .icon-tel {
      &:before {
        background: url('/images/icon-phone-yellow.svg') center center / contain no-repeat;
      }
    }

    .icon-mail {
      &:before {
        background: url('/images/icon-mail-yellow.svg') center center / contain no-repeat;
      }
    }

    .icon-form {
      &:before {
        background: url('/images/icon-inbox-yellow.svg') center center / contain no-repeat;
      }
    }
  }
}

@include from($desktop) {
  .navbar {
    .navbar-dropdown {
      transition: transform $nfx-transition-short ease-out;
      will-change: transform;
      transform: translateY(-110%);
      display: block;
      z-index: -1;

      .icon-tel {
        &:before {
          background: url('/images/icon-phone-yellow.svg') center center / contain no-repeat;
        }
      }

      .icon-mail {
        &:before {
          background: url('/images/icon-mail-yellow.svg') center center / contain no-repeat;
        }
      }

      .icon-form {
        &:before {
          background: url('/images/icon-inbox-yellow.svg') center center / contain no-repeat;
        }
      }
    }

    .navbar-item.has-dropdown {
      &:not(.has-full-dropdown) {
        &:hover {
          .navbar-dropdown {
            transform: translateY(0);
          }
        }
      }
    }

    .navbar-item {

      &.is-active {
        background-color: $color-grey-dark;

        > .icon-search {
          color: $color-white;

          &:before {
            background: url('/images/icon-magnifier-white.svg') center center / contain no-repeat;
          }
        }

        > .icon-tel {
          color: $color-white;

          &:before {
            background: url('/images/icon-phone-white.svg') center center / contain no-repeat;
          }
        }

        .navbar-dropdown {
          transform: translateY(0);
        }
      }
    }
  }
}
