.list {
  text-align: left;

  &.list-check {
    display: inline-block;
    list-style-type: none;

    > li {
      position: relative;
      padding: 0 0 1rem 2rem;

      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        content: '';
        width: 1.125rem;
        height: 1.125rem;
        position: absolute;
        left: 0;
        background: url('/images/icon-check.svg') center center no-repeat;
      }
    }

    &.is-circled {
      > li {
        &::before {
          padding: 0.5rem;
          border: 1px solid $color-primary;
          border-radius: 50%;
          background-size: 80%;
        }
      }

      .is-unchecked {
        &::before {
          background: none;
        }
      }
    }
  }

  &.list-inpage-nav {
    position: sticky;
    top: 7rem;
    list-style-type: none;
    padding-left: 1.25rem;
    border-left: 1px solid $color-grey;

    a {
      text-decoration: none;
    }

    li {

      &.is-active {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 100%;
          left: -1.25rem;
          border-left: 5px solid $color-primary;
        }
      }

      + li {
        margin-top: 1rem;
      }
    }
  }

  &.is-black {
    > li {
      &::before {
        background-image: url('/images/icon-check-dark.svg');
      }
    }
  }
}
