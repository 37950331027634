.footer {
  padding: 0 0 2.5rem;
  color: $color-white;
  background-color: $color-black;

  .footer-navblock {
    margin-bottom: 2rem;
    padding: 2rem 1rem 2.5rem;
    background-color: $color-grey-dark;

    .column {

      &:nth-child(4) {
        border: 0;
        @include from($tablet) {
          border-right: 1px solid $color-grey-medium;
        }
      }
    }

    li {
      padding-bottom: 0.75rem;

      @include from($desktop) {
        padding-bottom: 0;
      }

      a {
        font-weight: $font-weight-light;
      }
    }

    @include from($desktop) {
      padding-left: 0;
      padding-right: 0;
    }

    .store-badges {
      border-top: 1px solid $color-grey-medium;
      padding-top: 2rem;
      text-align: center;

      @include from($tablet) {
        border-top: 0;
        padding-top: 0;
      }

      .store-badge {
        display: inline-block;
        height: 140px;
        margin-left: 1rem;
      }
    }
  }
}
