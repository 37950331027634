
.service {
  .section-form {
    margin-bottom: -4rem;

    .teaser {
      transform: translateY(0);
      @include from($desktop) {
        transform: translateY(-50%);
      }
    }
  }

  .section-form {
    @include until($tablet) {
      width: 100vw;
      position: absolute;
      top: calc(100vw * 0.8);
      transform: translateY(-50%);
    }

    @include from($tablet) {
      position: relative;
      top: auto;
      transform: none;
      max-width: 550px;
      margin: 1.5rem auto -4rem;
    }

    @include from($desktop) {
      margin: 0 0 -4rem;
      max-width: none;
    }
  }

  @include until($desktop) {
    .hero:first-of-type {
      padding-top: 3rem;
    }

    .hero {
      &.has-mobile-padding-10-8 {
        padding-top: 0;
      }
    }

    .section-form {

      .teaser {
        padding: 1rem;
      }

      .button {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
