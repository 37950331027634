.is-highlighted,
.highlight {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: -0.125em;
    left: -0.125em;
    width: calc(100% + 0.25em);
    height: calc(100% + 0.25em);
    z-index: -1;
    background-color: $color-grey-light;
  }
}
