.section {
  padding: $section-padding-mobile;

  @include from($tablet) {
    padding: $section-padding;
  }

  &.has-no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.has-background-grey {
    background-color: $color-grey;
  }

  &.has-background-color-grey {
    background-color: $color-grey;
  }

  &.has-absolute-container {
    padding: $section-padding;
    position: relative;
    @include from($tablet) {
      padding: 0;
    }

    .container.is-absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.section-account-teaser {

  @include from($desktop) {
    &.is-overlapping-desktop {
      margin-top: -10rem;
    }

    .columns {
      &:first-child {
        .teaser {
          margin-right: 1rem;
        }
      }

      &:last-child {
        .teaser {
          margin-left: 1rem;
        }
      }
    }
  }
}

.section-geldanlage {

  @include from($desktop) {
    margin-top: -8rem;
  }
}

.section-more-topics,
.section-services,
.section-steps,
.section-faq,
.section-interest {
  text-align: center;
}

.section-banking {
  padding-bottom: 0;

  .columns {
    margin-bottom: 0;
  }

  .hero {
    background: none;
  }

  .hero-body {
    padding: 0;

    @include from($desktop) {
      padding: 3rem 1.5rem 0;
    }
  }

  .tab-panel {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 55%;
    @include from($desktop) {
      padding: 3rem 0;
      background: left 10% bottom -50px/ 49% no-repeat;
    }
  }
}

.section-topics {
  p {
    font-weight: $font-weight-light;
  }
}

.section-more-topics {
  padding: 2rem 1.5rem;
  @include from($tablet) {
    padding: 5rem 1.5rem;
  }
}

.section-services {
  @include until($desktop) {
    overflow-x: hidden;
  }

  .column {
    &.has-negative-margin-mobile {
      margin: 0 -1.5rem;
      padding-top: 2rem;
      @include from($tablet) {
        margin: 0;
      }
    }
  }
}

.section-insurance {
  &.is-split {
    @include from($tablet) {
      padding-top: 0;
    }
  }
}

.section-quicknav {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $color-grey;

  .column {
    + .column {
      margin-left: 2.5rem;
    }

    &:last-child {
      flex-grow: 0;
      flex-basis: auto;
    }
  }
}

.section-steps {
  .is-counted {
    &:before {
      @include fs-heading(2);
      @include from($tablet) {
        @include fs-heading(3);
        line-height: 1.7;
      }
      @include from($desktop) {
        line-height: 1.5;
      }
      @include from($widescreen) {
        line-height: 1.4;
      }
    }
  }
}

.section-faq {
  &.has-background-grey {
    .accordion-item {
      border-bottom: 1px solid $color-grey-light;

      &:first-child {
        border-top: 1px solid $color-grey-light;
      }
    }
  }
}

.section-video {
  position: relative;
  background: $color-grey;
  background-size: 0;

  &.hero::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    background: left top / cover no-repeat;
    background-image: inherit;
  }

  &.hero {
    &.is-split {
      &.has-mobile-padding-10-8 {
        &:before {
          left: 0;
          width: 100%;
          padding-top: 56.32%;
          position: relative;
          @include from($tablet) {
            left: 50%;
            width: 50%;
            padding-top: 0;
            position: absolute;
          }

        }
      }
    }
  }
}
