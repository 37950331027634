.play-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url('/images/play-button.svg') center center / contain no-repeat;
  z-index: 10;

  @include from($tablet) {
    width: 100px;
    height: 100px;
  }

  &.is-absolute {
    position: absolute;
  }

  &.is-centered-mobile {
    @include until($desktop) {
      left: 50%;
      top: 40vw;
      transform: translate(-50%, -50%);
    }
  }

  &.is-right-desktop {
    @include from($desktop) {
      right: 25%;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }

  &.is-right-tablet {
    @include from($tablet) {
      top: 50%;
      right: 25%;
      left: auto;
      transform: translate(50%, -50%);
    }
  }
}

.section-video {
  .play-button {
    &.is-right-tablet {
      @include until($tablet) {
        top: 28.5vw;
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
      }
      @include from($tablet) {
        top: 50%;
        right: 25%;
        left: auto;
        transform: translate(50%, -50%);
      }
    }
  }
}
