.tabs {
  ul {
    border-bottom-width: 1px;
    overflow: visible;

    a {
      margin-bottom: 0;
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }

    li:not(.is-active) {
      a {
        border-bottom-color: transparent;
      }
    }
  }
}

.tab-panel {
  padding: 3rem 0;

  p {
    a {
      padding: 0.25rem 0;
      border-bottom: 3px solid $color-primary;
      text-decoration: none;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;

        @include from($tablet) {
          display: none;
        }
      }
    }
  }

  &.has-background {
    background: bottom left/ 60% no-repeat;
  }
}

.tab-content {

  &:not(.is-active) {
    display: none;
  }
}
