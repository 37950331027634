.cookie-layer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  padding: 1rem;
  color: $color-white;
  background-color: $color-grey-dark;
  transform: translate(-50%, 100%);
  transition: $nfx-transition-short ease-in-out;
  z-index: 99;

  @include from($desktop) {
    padding: 2rem 3rem;
  }

  &.is-visible {
    transition-delay: 2s;
    transform: translate(-50%, 0);
  }
}
