.accordion {
  border-bottom: 1px solid $color-grey;
  text-align: left;

  + .accordion {
    margin-top: 2rem;
  }

  .accordion-headline {
    padding-left: 1.25rem;
    margin-bottom: 1rem;
  }

  .accordion-title {
    position: relative;
    display: block;
    padding: 1.25rem 3rem 1.25rem 1.25rem;
    cursor: pointer;
    border-top: 1px solid $color-grey;

    &::after {
      position: absolute;
      content: '';
      width: 1.25rem;
      height: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: center center / contain no-repeat;
    }

    &.accordion-item {
      &::after {
        content: none;
      }
    }

    &.has-icon {
      padding-left: 3rem;

      &::before {
        position: absolute;
        content: '';
        width: 2rem;
        height: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: center center / auto 2rem no-repeat;
      }

      + .accordion-content {
        padding-left: 3rem;
      }
    }

    &::after {
      left: auto;
      right: 1.25rem;
      background-image: url('/images/icon-plus.svg');
    }

    &.icon-mouse {
      &::before {
        background-image: url('/images/icon-mouse.svg');
      }
    }

    &.icon-secured {
      &::before {
        background-image: url('/images/icon-secured.svg');
      }
    }

    &.icon-app {
      &::before {
        background-image: url('/images/icon-app.svg');
      }
    }

    &.icon-star {
      &::before {
        background-image: url('/images/icon-star.svg');
      }
    }

    &.is-active {
      &::after {
        background-image: url('/images/icon-minus.svg');
      }

      + .accordion-content {
        max-height: 50rem;
      }
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 3rem 0 1.25rem;
    transition: max-height 0.3s ease-in-out;

    p {
      font-weight: $font-weight-light;
    }

    :last-child {
      margin-bottom: 1rem;
    }
  }
}
