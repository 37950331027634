$interest-padding: 3rem;

.interest {
  display: inline-block;
  position: relative;
  padding-right: $interest-padding;
  font-size: 5rem;
  font-weight: $font-weight-bold;

  .interest-prefix {
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: $font-weight-normal;

    &.is-left {
      left: 0;
      transform: none;
    }
  }

  &::before {
    content: '%';
    position: absolute;
    top: 0.45rem;
    left: calc(100% - #{$interest-padding} + 0.25rem);
    font-size: 3.125rem;
  }

  &::after {
    content: 'p.a.';
    position: absolute;
    bottom: 1.1rem;
    left: calc(100% - #{$interest-padding} + 0.25rem);
    font-size: 1rem;
    font-weight: $font-weight-normal;
  }
}
