.media {

  & + .media {
    border-top: 0;
  }

  &.is-full-height {
    height: 100%;

    .media-content {
      height: 100%;
      display: flex;
      flex-basis: 100%;
      flex-direction: column;

    }

    p {
      flex-grow: 1;
    }
  }

  .media-icon {
    height: auto;
    width: 3rem;
    transform: translateY(-0.5rem);

    &[style] {
      background-size: 0;
    }

    img {
      width: 48px;
      height: 48px;
    }
  }
}

.image {
  width: 70px;
  height: 70px;
  margin: 0 auto;

  &.is-avatar {
    width: 96px;
    height: 96px;
  }

  .is-rounded {
    border-radius: 50%;
  }
}


.is-ie {

  .media-icon {

    svg {
      display: none;
    }

    &[style] {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 3rem;
    }
  }

  .image {

    &.has-svg-fallback {

      svg {
        display: none;
      }

      &[style] {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
