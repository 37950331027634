.logo {
  padding: 0.25rem 0 0.25rem 1rem;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  border-left: 5px solid $color-primary;
  text-decoration: none;

  @include from($desktop) {
    padding: 0.5rem 0 0.5rem 0.5rem;
    font-size: 22px;
  }
}

.logo-image {
  width: 60px;
  height: 40px;
}
