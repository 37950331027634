$border-width: 3px;

.teaser {
  position: relative;
  padding: 2rem 0 2.5rem;
  background-color: $color-grey;
  border: $border-width solid $color-black-transparent;
  text-align: center;

  .is-offer & {
    @include until($tablet) {
      margin-top: 1rem;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: $border-width * -1;
    top: $border-width * -1;
    height: calc(100% + #{$border-width} * 2);
    border-right: 7px solid $color-primary;
  }

  &.has-no-border {
    border: 0;
  }

  &.has-highlight-border {
    border: 7px solid $color-primary;
  }

  &.is-centered {
    text-align: center;
  }

  &.is-full-height {
    height: 100%;
  }

  &.is-vcentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.is-highlighted {
    margin-top: -0.5rem;
    height: calc(100% + 1rem);
    background-color: $color-white;
  }

  &.has-background-image {
    background: center bottom / cover no-repeat;
  }

  &.is-transparent {
    background-color: transparent;
  }

  &.is-plain {
    &::after {
      content: none;
    }
  }

  &.is-decorated {
    border-bottom: 7px solid $color-primary;

    &::after {
      content: none;
    }
  }

  &.teaser-download {
    text-align: left;

    &::after {
      content: none;
    }

    .filename {
      display: inline-block;
      padding: 0.5rem 1rem;
    }

    @include until($tablet) {
      word-break: break-word;
    }
  }

  &.has-tiny-padding {
    padding: 1rem 1.5rem;
  }

  &.has-small-padding {
    padding: 2rem 3rem;
  }

  &.has-medium-padding {
    padding: 2.5rem 3.5rem;

    @include until(321px) {
      padding: 1rem;
    }
  }

  &.has-no-padding {
    padding: 0;
  }

  &.has-bottom-button {
    @include from($desktop) {
      .button {
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .teaser-highlight {
    @include fs-heading(3);

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-primary;
    padding: 1rem 1.5rem;
    white-space: nowrap;

    .is-offer & {
      @include fs-heading(5);
    }
  }

  .teaser-headline {
    margin-bottom: 0.5rem;
  }

  .teaser-subline {
    margin-bottom: 0.5rem;
  }

  .teaser-content {
    margin-bottom: 1rem;
  }

  .interest {
    margin-bottom: 0.5rem;
  }
}
