// Import Fonts

// Import Configuration
@import 'settings/settings';

// Impprt Design System
@import 'node_modules/nfx-design-system/scss/base/setup';
@import 'node_modules/nfx-design-system/scss/mixins/font-scale';
@import 'node_modules/nfx-design-system/scss/base/colors';
@import 'node_modules/nfx-design-system/scss/base/typography';
@import 'node_modules/nfx-design-system/scss/base/transitions';
@import 'node_modules/nfx-design-system/scss/base/animations';
@import 'node_modules/nfx-design-system/scss/components/intrinsic-box';
@import 'node_modules/nfx-design-system/scss/components/modal';

// Import Bulma
@import 'node_modules/bulma/sass/utilities/all';
@import 'node_modules/bulma/sass/base/helpers';
@import 'node_modules/bulma/sass/elements/container';
@import 'node_modules/bulma/sass/elements/button';
@import 'node_modules/bulma/sass/components/navbar';
@import 'node_modules/bulma/sass/layout/hero';
@import 'node_modules/bulma/sass/layout/section';
@import 'node_modules/bulma/sass/grid/columns';
@import 'node_modules/bulma/sass/components/tabs';
@import 'node_modules/bulma/sass/components/media';
@import 'node_modules/bulma/sass/elements/form';
@import 'node_modules/bulma/sass/components/modal';
@import 'node_modules/bulma/sass/components/message';

@import 'node_modules/flickity/dist/flickity.min';

// Import Resets
@import 'base/reset';
@import 'base/fonts';
@import 'base/typography';

// Include global Styles
@import 'base/global';
@import 'base/grid';

// Include local Components
@import 'components/hero';
@import 'components/header';
@import 'components/footer';
@import 'components/teaser';
@import 'components/nav';
@import 'components/navbar';
@import 'components/button';
@import 'components/media-object';
@import 'components/list';
@import 'components/interest';
@import 'components/link';
@import 'components/box';
@import 'components/tabs';
@import 'components/badge';
@import 'components/store-badge';
@import 'components/accordion';
@import 'components/divider';
@import 'components/logo';
@import 'components/form';
@import 'components/table';
@import 'components/modal';
@import 'components/search';
@import 'components/intrinsic-box';
@import 'components/play-button';
@import 'components/cookie-layer';

// Include Sections
@import 'base/section';

// Include page specific styles
@import 'pages/3-steps';
@import 'pages/service';
@import 'pages/404';
@import 'pages/tagesgeld';

