.nav {

  li {
    & + li {
      margin-top: 0.75rem;
    }
  }

  &.is-horizontal {
    li {
      display: inline-block;

      & + li {
        margin-top: 0;
        margin-left: 3rem;
      }
    }
  }

  &.is-plain {
    list-style-type: none;

    a {
      text-decoration: none;
    }
  }

  &.has-title {
    >:first-child {
      font-weight: $font-weight-normal;
    }
  }
}
