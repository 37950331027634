.link {
  display: inline-block;
  color: inherit;

  &.link-large {
    @include font-scale(37px, 42px);
  }

  &.is-plain {
    text-decoration: none;
  }

  &.is-decorated {
    position: relative;
    text-decoration: none;
    padding-bottom: 0.125rem;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      border-bottom: 3px solid $color-primary;
    }
  }

  &.has-icon {
    position: relative;
    padding-left: 2.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
    }

    &.is-vertical {
      text-align: center;
      padding-top: 1.5rem;
      padding-left: 0;
      font-size: 10px;

      &::before {
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translateX(-50%);
        display: block;
      }
    }

    &.icon-download {
      padding-left: 0;
      margin-left: 2.5rem;

      &::before {
        left: -1.75rem;
        transform: translateY(-60%);
        width: 28px;
        height: 28px;
        background: url('/images/icon-download.svg') center center / auto 0.825rem no-repeat;
      }
    }
  }

  &.icon-tel {
    text-decoration: none;

    &::before {
      background: url('/images/icon-phone.svg') center center / contain no-repeat;
    }
  }

  &.icon-search {
    text-decoration: none;

    &::before {
      background: url('/images/icon-magnifier.svg') center center / contain no-repeat;
    }
  }

  &.icon-banking {
    text-decoration: none;

    &::before {
      background: url('/images/icon-banking.svg') center center / contain no-repeat;
    }
  }

  &.icon-mail {
    text-decoration: none;

    &::before {
      background: url('/images/icon-mail.svg') center center / contain no-repeat;
    }
  }

  &.icon-form {
    &::before {
      background: url('/images/icon-inbox.svg') center center / contain no-repeat;
    }
  }
}
