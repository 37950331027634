@font-face {
  font-family: RenaultLife;
  src: url('../fonts/renault-life/RenaultLifeWeb-Regular.eot'); /* IE9 */
  src: url('../fonts/renault-life/RenaultLifeWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/renault-life/RenaultLifeWeb-Regular.woff') format('woff'); /* Modern Browsers */
  font-weight: 400;
}

@font-face {
  font-family: RenaultLife;
  src: url('../fonts/renault-life/RenaultLifeWeb-Light.eot'); /* IE9 */
  src: url('../fonts/renault-life/RenaultLifeWeb-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/renault-life/RenaultLifeWeb-Light.woff') format('woff'); /* Modern Browsers */
  font-weight: 300;
}

@font-face {
  font-family: RenaultLife;
  src: url('../fonts/renault-life/RenaultLifeWeb-Bold.eot'); /* IE9 */
  src: url('../fonts/renault-life/RenaultLifeWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/renault-life/RenaultLifeWeb-Bold.woff') format('woff'); /* Modern Browsers */
  font-weight: 700;
}
